export class GeofenceUtil {
  constructor() {
    if (typeof GeofenceUtil.instace === 'object') {
      return GeofenceUtil.instace;
    }
    GeofenceUtil.instace = this;
    return this;
  }

  // Transformar un WKT a un poligono
  wktToCoordinates(wkt) {
    const coordsString = wkt.replace('POLYGON((', '').replace('))', '');
    const coordsArray = coordsString.split(',').map((coord) => {
      const [x, y] = coord.trim().split(' ').map(Number);
      return [x, y];
    });

    return coordsArray;
  }

  isPointInPolygon(polygon, point) {
    const [x, y] = point;
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];

      // Verificar si el punto está en el rango vertical del segmento
      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  getIncidents(geofence, incidents, all = false) {
    const result = [];
    const summary = {
      total: 0,
      medico: 0,
      seguridad: 0,
      proteccion_civil: 0,
      servicios_publicos: 0,
    };

    incidents.forEach((element) => {
      const data = element;
      const isInside = all || this.isPointInPolygon(geofence, [data.latitude, data.longitude]);
      if (isInside) {
        result.push(data);
        summary.total++;
        summary[data.category]++;
      }
    });

    return { summary };
  }

  getCameras(geofence, cameras, all = false) {
    const result = [];
    const summary = {
      total: 0,
      up: 0,
      down: 0,
    };

    cameras.forEach((element) => {
      const data = element;
      const isInside = all || this.isPointInPolygon(geofence, [data.latitude, data.longitude]);
      if (isInside) {
        result.push(data);
        summary.total++;
        summary[data.status]++;
      }
    });

    return { summary };
  }

  getDevices(geofence, devices, all = false) {
    const result = [];
    const summary = {
      total: 0,
      up: 0,
      offline: 0,
      unknown: 0,
      down: 0,
    };

    devices.forEach((element) => {
      const data = element;
      const isInside = all || this.isPointInPolygon(geofence, [data.latitude, data.longitude]);
      if (isInside) {
        result.push(data);
        summary.total++;
        summary[data.status]++;
      }
    });

    return { summary };
  }

  getbuses(geofence, buses, all = false) {
    const result = [];
    const summary = {
      total: 0,
      up: 0,
      down: 0,
    };

    buses.forEach((element) => {
      const data = element;
      const isInside = all || this.isPointInPolygon(geofence, [data.latitude, data.longitude]);
      if (isInside) {
        result.push(data);
        summary.total++;
        summary[data.status]++;
      }
    });

    return {
      summary,
    };
  }

  getSensors(geofence, sensors, all = false) {
    const result = [];
    const summary = {
      total: 0,
      up: 0,
      reported: 0,
      alert: 0,
      down: 0,
    };

    sensors.forEach((element) => {
      const data = element;
      const isInside = all || this.isPointInPolygon(geofence, [data.latitude, data.longitude]);
      if (isInside) {
        result.push(data);
        summary.total++;
        summary[data.status]++;
      }
    });

    return { summary };
  }

  getSummary(geofence, incidentsData, camerasData, devicesData, busesData, sensorsData) {
    const coords = geofence.all || this.wktToCoordinates(geofence.area);
    console.log('obteniendo resumen');
    const incidents = this.getIncidents(coords, incidentsData, geofence.all);
    const cameras = this.getCameras(coords, camerasData, geofence.all);
    const devices = this.getDevices(coords, devicesData, geofence.all);
    const buses = this.getbuses(coords, busesData, geofence.all);
    const sensors = this.getSensors(coords, sensorsData, geofence.all);

    return {
      incidents,
      cameras,
      devices,
      buses,
      sensors,
    };
  }
}
